import React from 'react';
import imgSrc from 'arcdynamic-resize';
import Icon from '../Icon';
import svgClose from '../../svgs/close.svg';
import Control from 'react-control';
import Textarea from '../Textarea';
import request from 'arcdynamic-request';

function getProducts(sku) {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.product.get',
		schema: '[id,name,sku,desc,attributeValues,optionalAttributes,quantityStatusCode,limitPerOrder]',
		options: {
			filter: {
				siblingSku: sku,
			},
			limit: {
				count: 100,
			},
			flag: {
				attributeValue: true,
			},
		},
	}, {
		expires: 1000*60*60,
	})
}

export default React.createClass({
	_renderInstructions() {
		const { allowSpecialInstructions, onInstructions, instructions } = this.props;

		if (!allowSpecialInstructions) return null;

		if (this.state.edit) {
			return (
				<div>
					<Textarea autoFocus={true} value={instructions || ''} onChange={(e)=> onInstructions(e.target.value)}/>
					<button onClick={(e)=> { e.preventDefault(); this.setState({edit: false})}}>Done</button>
				</div>
			);
		}

		if (instructions) {
			return (
				<div>
					<div><strong>Special Instructions</strong> (<a href='#' onClick={(e)=> { e.preventDefault(); this.setState({edit: true})}}>Edit</a>)</div>
					<div>{ instructions }</div>
				</div>
			);
		}

		return (<a href='#' onClick={(e)=> { e.preventDefault(); this.setState({edit: true})}}>Add special instructions</a>);
	},
	_fetch(sku) {
		getProducts(sku).then(res => {
			if (!this.isMounted()) return;
			this.setState({products: res.data});
		})
	},
	getDefaultProps() {
		return {
			thumbWidth: 80,
			optionalAttributes: {},
			instructions: '',
		};
	},
	getInitialState() {
		return {
			edit: false,
			products: null,
		};
	},
	componentDidMount() {
		this._fetch(this.props.product.sku);
	},
	render() {
		const {
			product, 
			onRemove, 
			onChange, 
			quantity,
			allowOptionalAttributes,
			optionalAttributes,
			onOptionalAttribute,
			thumbWidth,
			sibling,
		} = this.props;

		const thumb = product.files.filter(el => el.type === 'image' && el.directory === 'img/products/').shift();
		const src = thumb ? imgSrc(thumb.url, thumbWidth) : null;
		const attrProducts = this.state.products ? this.state.products.filter(el => el.attributeValues.length) : [];

		return (
			<div className='Product'>
				<div className='Product_body'>
					<div className='Product_img' style={{width: thumbWidth}}>
						<img src={src} style={{maxHeight: thumbWidth}}/>
					</div>
					<div className='Product_desc'>
						<div><strong>{product.name}</strong></div>
						<div>SKU: {product.sku}</div>
						{ 
							product.desc ? <div>{product.desc}</div> : null 
						}
						{ this._renderInstructions() }
					</div>
					{
						this.state.products ? (
							<div className='Product_controls'>
								{
									attrProducts.length > 1 ? (
										<div className='Product_attr'>
											<div className='Product_attr_label'>{attrProducts[0].attributeValues[0].attributeName}:</div>
											<select required={attrProducts.length && !sibling && quantity} name={'sibling-'+product.id} value={sibling ? sibling.sku : ''} onChange={(e)=> {
												const siblingSku = e.target.value;
												const sibling = attrProducts.filter(el => el.sku === siblingSku)[0];

												if (siblingSku) {
													onChange(product, quantity, sibling);
												} else {
													onChange(product, 0, sibling);
												}
											}}>
												<option/>
												{
													attrProducts.map(el => <option key={el.sku} value={el.sku}>{el.attributeValues[0].name}</option>)
												}
											</select>
										</div>
									) : null
								}
								{
									allowOptionalAttributes && product.optionalAttributes && product.optionalAttributes.length ? product.optionalAttributes.map(el => {
										return (
											<div className='Product_attr' key={el.code}>
												<div className='Product_attr_label'>{el.name.charAt(0).toUpperCase() + el.name.slice(1)}:</div>
												<select required={product.optionalAttributes && !product.optionalAttributes.every(el => optionalAttributes.hasOwnProperty(el.code)) && quantity} name={'optional-'+el.code+'-'+product.id} value={optionalAttributes[el.code] || ''} onChange={(e)=> onOptionalAttribute(el.code, e.target.value)}>
													<option/>
													{
														el.values.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
													}
												</select>
											</div>
										)
									}) : null
								}
								<div className='Product_quantity'>
									<div className='Product_quantity_label'>Qty{Number(product.limitPerOrder) ? <span> (limit {Number(product.limitPerOrder)})</span> : null }:</div>
									<input name={'quantity-'+product.id} type='number' max={Number(product.limitPerOrder) || null} value={quantity || ''} onChange={(e)=> {
										let value = Number(e.target.value);

										const limitPerOrder = Number(product.limitPerOrder);

										if (limitPerOrder) {
											value = Math.min(limitPerOrder, value);
										}

										if (!value || value < 0) {
											value = 0;
										}

										onChange(product, value, sibling);
									}}/>
								</div>
							</div>
						) : null
					}
					{
						onRemove ? <Control className='Product_remove' onClick={()=> onRemove(product)}><Icon svg={svgClose}/></Control> : null
					}
				</div>
			</div>
		);
	},
})
