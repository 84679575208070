import React from 'react';
import request from 'arcdynamic-request';
import { setUser } from '../../actions';
import exc from '../../exception';
import Alert from '../Alert';

export default React.createClass({
	_handleSubmit(e) {
		e.preventDefault();

		this.setState({
			isBusy: true,
			error: false,
		});

		const username = this.refs.username.value;
		const password = this.refs.password.value;

		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.login',
				params: [username, password],
			}),
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Customer.get',
				schema: '{typeCode,id,email,username,person{firstName,lastName},_ext}',
			}).then(res => setUser(res.data)),
		]).then(values => {
			if (!values[0].success && this.isMounted()) {
				this.setState({
					isBusy: false,
					error: values[0].message,
				})
			}
		}).catch(exc);
	},
	getInitialState() {
		return {
			isBusy: false,
			error: false,
		};
	},
	render() {
		const { isBusy, error } = this.state;

		return (
			<form className='Login' onSubmit={this._handleSubmit}>
				<input disabled={isBusy} ref='username' autoFocus type='text' placeholder='Username'/>
				<input disabled={isBusy} ref='password' type='password' placeholder='Password'/>
				<Alert type='error'>{error}</Alert>
				<button disabled={isBusy} data-is-busy={isBusy || null}>Submit</button>
				<div className='Login_links'>
					<a href={arc.path.base+'account/signup'}>Sign up</a>
					<a href={arc.path.base+'account/password'}>Forgot password?</a>
				</div>
			</form>
		);
	},
});
