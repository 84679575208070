import React from 'react';
import request from 'arcdynamic-request';
import CustomerOrderDetail from '../CustomerOrderDetail';
import Paginate from '../Paginate';
import Results from '../Results';
import exception from '../../exception';
import history from '../../history';
import querystring from 'querystring';

const limitCount = 10;

export default React.createClass({
	getInitialState() {
		return {
			items: null,
			totalCount: false,
			page: 1,
			usernames: false,
		};
	},
	_fetchUsernames() {
		const params = ['AVI_SALES_REP_USERS', {
			param: {
				SALES_REPRESENTATIVE: this.props.username,
			},
		}];

		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params,
		}, {
			expires: 1000*60*1,
		}).then(res => {
			if (!this.isMounted()) return;

			let usernames = false;

			if (Array.isArray(res.data)) {
				usernames = res.data.sort((a,b)=> {
					const A = (a.FIRST_NAME ? a.FIRST_NAME.toUpperCase()+' ' : '')+(a.LAST_NAME ? a.LAST_NAME.toUpperCase()+' ' : '')+(a.USERNAME ? `(${a.USERNAME})` : '');
					const B = (b.FIRST_NAME ? b.FIRST_NAME.toUpperCase()+' ' : '')+(b.LAST_NAME ? b.LAST_NAME.toUpperCase()+' ' : '')+(b.USERNAME ? `(${b.USERNAME})` : '');

					if (A < B) {
						return -1;
					}
					if (A > B) {
						return 1;
					}

					return 0;
				})
			}

			this.setState({
				usernames,
			});
		}).catch(exception);
	},
	_fetch() {
		this.setState({isFetching: true});

		const { 
			username,
			status,
			search,
		} = this.props.location.query;
		const page = Number(this.props.location.query.page) || 1;

		Promise.all([
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.dataquery.execute',
				params: [
					'AVI_SALES_REP_COUNT',
					{
						param: {
							['SALES_REPRESENTATIVE']: this.props.username,
							REQUEST_TYPE: '%',
							ORDER_STATUS_CODE: status || '%',
							USERNAME: username || '%',
							search: search ? '%'+search+'%' : '%',
						}
					}
				],
			}, {
				expires: 1000*60*5, // 5 minutes
			}),
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.dataquery.execute',
				params: [
					'AVI_SALES_REP_SEARCH',
					{
						param: {
							['SALES_REPRESENTATIVE']: this.props.username,
							REQUEST_TYPE: '%',
							ORDER_STATUS_CODE: status || '%',
							USERNAME: username || '%',
							search: search ? '%'+search+'%' : '%',
							limit: limitCount,
							offset: (limitCount * page) - limitCount,
						}
					}
				],
			}, {
				expires: 1000*60*5, // 5 minutes
			})
		]).then(values => {
			if (!this.isMounted()) return;

			this.setState({
				isFetching: false,
				page: page,
				items: values[1] ? values[1].data : [],
				totalCount: values[0] && values[0].data && values[0].data[0] ? Number(values[0].data[0].count) : false,
			})
		}).catch(exception);
	},
	_setQuery(key, value) {
		const query = {...this.props.location.query};
		delete query.page;

		if (value) {
			query[key] = value;
		} else {
			delete query[key];
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_clearSearch() {
		this._setQuery('search', false);
	},
	componentDidMount() {
		this._fetch();
		this._fetchUsernames();
	},
	componentDidUpdate(prevProps) {
		if (this.props.location.href !== prevProps.location.href) {
			this._fetch();
		}
	},
	render() {
		const { 
			items,
			page,
			totalCount,
			usernames,
			isFetching,
		} = this.state;

		const { location } = this.props;

		if (items == null) return <div/>;

		return (
			<div className='CustomerHistory'>
				<h1>Customer Request History</h1>
				<form className='CustomerHistory_search' onSubmit={e => e.preventDefault()}>
					<input type='text' onChange={(e)=> this._setQuery('search', e.target.value)} value={location.query.search || ''} autoFocus placeholder='Search addressee, email, or phone'/>
				</form>
				<div className='CustomerHistory_nav'>
					<div className='CustomerHistory_nav_results'>
						<Results count={limitCount} page={page} itemCount={items.length} totalCount={totalCount} clearSearch={this._clearSearch} query={location.query && location.query.search}/>
					</div>
					<div className='CustomerHistory_nav_filter'>
						User: <select value={location.query.username || ''} onChange={(e)=> this._setQuery('username', e.target.value)}>
							<option value=''>All</option>
							{
								usernames ? usernames.map(el => (
									<option value={el.USERNAME} key={el.USERNAME}>
									{el.FIRST_NAME ? el.FIRST_NAME+' ' : null}
									{el.LAST_NAME ? el.LAST_NAME+' ' : null}
									{el.USERNAME ? `(${el.USERNAME})` : null}
									</option>
								)) : null
							}
						</select>
					</div>
					<div className='CustomerHistory_nav_filter'>
						Status: <select value={location.query.status || ''} onChange={(e)=> this._setQuery('status', e.target.value)}>
							<option value=''>All</option>
							<option value='invalid'>Invalid</option>
							<option value='pending'>Pending</option>
							<option value='processing'>Processing</option>
							<option value='completed'>Completed</option>
							<option value='cancelled'>Cancelled</option>
							<option value='returned'>Returned</option>
							<option value='refunded'>Refunded</option>
						</select>
					</div>
				</div>
				<div className='CustomerHistory_body' data-is-busy={isFetching || null}>
					{
						items ? items.map(el => {
							const heading = [];

							if (el.FIRST_NAME) {
								heading.push(el.FIRST_NAME);
							}
							if (el.LAST_NAME) {
								heading.push(el.LAST_NAME);
							}
							if (el.USERNAME) {
								heading.push('('+el.USERNAME+')');
							}

							return (
								<div className='CustomerHistory_order' key={el.PURCHASE_ORDER_CODE}>
									<div className='CustomerHistory_order_label'>
										{heading.join(' ')}
										{el.EMAIL ? <a href={'mailto:'+el.EMAIL} target='_blank' className='CustomerHistory_order_label_email'>{el.EMAIL}</a> : null}
									</div>
									<CustomerOrderDetail order={el}/>
								</div>
							)
						}) : null
					}
					<div className='CustomerHistory_paginate'>
						<Paginate query={location.query} pathname={location.pathname} count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
					</div>
				</div>
			</div>
		);
	},
});
