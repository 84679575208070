import React from 'react';
import request from 'arcdynamic-request';
import OrderDetail from '../OrderDetail';
import Paginate from '../Paginate';
import Results from '../Results';
import exc from '../../exception';
import history from '../../history';
import querystring from 'querystring';

const limitCount = 10;

export default React.createClass({
	getInitialState() {
		return {
			items: null,
			totalCount: null,
			page: 1,
		};
	},
	_fetch() {
		const filter = {};
		const page = Number(this.props.location.query.page) || 1;

		if (this.props.location.query.status) {
			filter.statusCode = this.props.location.query.status;
		}

		if (this.props.location.query.search) {
			filter.searchShipping = this.props.location.query.search;
		}

		this.setState({isFetching: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Order.get',
			params: [{
				filter,
				limit: {
					count: limitCount,
					offset: (limitCount * page) - limitCount,
				},
				flag: {
					shipment: true,
				},
				order: 'desc',
				count: 2,
			}]
		}, {
			expires: 1000*60*1,
		}).then((res)=>{
			if (this.isMounted()) {
				if (res && res.success) {
					this.setState({
						items: res.data,
						totalCount: res.count,
						page: page,
						isFetching: false,
					})
				} else {
					this.setState({
						items: [],
						totalCount: false,
						page: page,
						isFetching: false,
					})
				}
			}
		}).catch(exc);
	},
	_setQuery(key, value) {
		const query = {...this.props.location.query};
		delete query.page;

		if (value) {
			query[key] = value;
		} else {
			delete query[key];
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_clearSearch() {
		this._setQuery('search', false);
	},

	componentDidMount() {
		this._fetch();
	},
	componentDidUpdate(prevProps) {
		if (this.props.location.href !== prevProps.location.href) {
			this._fetch();
		}
	},
	render() {
		const { 
			items,
			page,
			totalCount,
			isFetching,
		} = this.state;

		const { location } = this.props;

		if (items == null) return <div/>;

		return (
			<div className='OrderHistory'>
				<h1>Request History</h1>
				<form className='OrderHistory_search' onSubmit={e => e.preventDefault()}>
					<input type='text' onChange={(e)=> this._setQuery('search', e.target.value)} value={location.query.search || ''} autoFocus placeholder='Search shipping address'/>
				</form>
				<div className='OrderHistory_nav'>
					<div className='OrderHistory_nav_results'>
						<Results count={limitCount} page={page} itemCount={items.length} totalCount={totalCount} clearSearch={this._clearSearch} query={location.query && location.query.search}/>
					</div>
					<div className='OrderHistory_nav_filter'>
						Status: <select value={this.props.location.query.status || ''} onChange={(e)=> this._setQuery('status', e.target.value)}>
							<option value=''>All</option>
							<option value='invalid'>Invalid</option>
							<option value='pending'>Pending</option>
							<option value='processing'>Processing</option>
							<option value='completed'>Completed</option>
							<option value='cancelled'>Cancelled</option>
							<option value='returned'>Returned</option>
							<option value='refunded'>Refunded</option>
						</select>
					</div>
				</div>
				<div className='OrderHistory_body' data-is-busy={isFetching || null}>
					{
						items.map(el => (
							<div key={el.id} className='OrderHistory_order'>
								<OrderDetail order={el}/>
							</div>
						))
					}
				</div>
				<div className='OrderHistory_paginate'>
					<Paginate query={this.props.location.query} pathname={this.props.location.pathname} count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
				</div>
			</div>
		);
	},
});
