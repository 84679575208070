function merchReqs({ purposeValue }) {
	return Boolean(purposeValue);
}

export default (requestType, info)=> {
	let ret;

	switch (requestType) {
		case 'merchandise':
			ret = merchReqs(info);
			break;
	}

	return ret;
};