import React from 'react';
import Address from '../Address';

function formatDate(unixTimestamp) {
	const d = new Date(unixTimestamp*1000);
	return `${d.getUTCMonth()+1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

export default React.createClass({
	getInitialState() {
		return {
			isOpen: false,
		};
	},

	_toggle() {
		this.setState({isOpen: !this.state.isOpen});
	},

	render() {
		const { order, productLabel, purpose, billingAddress } = this.props;

		return (
			<div className='MiniOrder'>
				<div className='MiniOrder_head'>
					{
						order.created ? (
							<div className='MiniOrder_head_sec'>
								<div className='MiniOrder_head_sec_label'>Request Placed</div>
								{formatDate(order.created)}
							</div>
						) : null
					}
					{
						order.shipmentDate ? (
							<div className='MiniOrder_head_sec'>
								<div className='MiniOrder_head_sec_label'>Requested Delivery Date</div>
								{formatDate(order.shipmentDate)}
							</div>
						) : (
							null
						)
					}	
					<div className='MiniOrder_head_sec'>
						<div className='MiniOrder_head_sec_label'>Status</div>
						<div className={`MiniOrder_head_sec_status MiniOrder_head_sec_status--${order.statusCode}`}>
							{order.statusCode[0].toUpperCase() + order.statusCode.slice(1)}
						</div>
					</div>				
					<div className='MiniOrder_head_sec'>
						<div className='MiniOrder_head_sec_label'>Request Number</div>
						{order.purchaseOrderCode}
					</div>
				</div>
				<button className='MiniOrder_toggle' type='button' onClick={this._toggle}>
					{this.state.isOpen ? <span>- Minimize Request Details</span> : <span>+ Expand Request Details</span>}
				</button>
				<div className='MiniOrder_body' hidden={!this.state.isOpen}>
					<div className='MiniOrder_sec'>
						<h4>{productLabel}</h4>
						<ul>
						{
							order.products ? order.products.map(el => (
								<li key={(el.__type==='subscription.Product' ? '0_' : '1_')+el.productId}>
									{el.quantity} x {el.name}
									{
										el.fixedAttributeValues ? (
											el.fixedAttributeValues.map(x=>(
												<span key={el.__type==='subscription.Product' ? x.id : x.attributeValueId} className='MiniOrder_products_attribute'>– {x.attributeName}: {x.name}</span>
											))
										) : null
									}
									{
										el.optionalAttributeValues ? (
											el.optionalAttributeValues.map(x=><span key={x.attributeValueId} className='MiniOrder_products_attribute'>– {x.attributeName}: {x.name}</span>)
										) : null
									}
									{
										el.subscriptionPeriod ? (
											<span className='MiniOrder_products_subscription'>
												Subscription: {el.subscriptionPeriod.name}
											</span>
										) : null
									}
								</li>
							)) : null
						}
						</ul>
					</div>
					{
						order.message ? (
							<div className='MiniOrder_sec'>
								<h4>Special Instructions</h4>
								<div className='MiniOrder_instructions'>
									{ order.message }
								</div>
							</div>
						) : null
					}
					{
						purpose ? (
							<div className='MiniOrder_sec'>
								<h4>Purpose of Request</h4>
								<div className='MiniOrder_instructions'>
									{ purpose }
								</div>
							</div>
						) : null
					}
					<div className='MiniOrder_sec'>
						<h4>Shipment Details</h4>
						{ 
							order.shipments.length ? (
								<ul>
									{
										order.shipments.map(el => 
											<li key={el.trackingNumber}>
												<a href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${el.trackingNumber}`} target='_blank'>{el.trackingNumber}</a> – {el.statusCode[0].toUpperCase() + el.statusCode.slice(1)}
												{
													el.deliveryDate ? <span> {formatDate(el.deliveryDate)}</span> : null
												}
											</li>)
									}
								</ul>
							) : <div className='MiniOrder_no-ship-sherlock'>No shipments</div>
						}
					</div>
					<div className='MiniOrder_addrs'>
						<div className='MiniOrder_sec'>
							<h4>Ship to</h4>
							<Address address={order.address}/>
						</div>
						{
							billingAddress ? (
								<div className='MiniOrder_sec'>
									<h4>Customer Account / Business Name</h4>
									<Address address={billingAddress}/>
								</div>
							) : null
						}
					</div>
				</div>
			</div>
		);
	},
});