import React from 'react';
import AddressPicker from '../AddressPicker';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import Textarea from '../Textarea';

export default React.createClass({
	_setAddressId(shippingAddressId) {
		setData(this.props.requestType, {...this.props.data, shippingAddressId });
	},
	_isValidDate(date) {
		if (!date) {
			return 'Date missing';
		}

		if (date < new Date()) {
			return 'Delivery date no longer available, please pick a new delivery date.';
		}

		return true;
	},
	render() {
		const { data, requestType } = this.props;

		return (
			<div>
				<h2>Shipping Address</h2>
				<p>Where would you like this shipped?<br/><br/><a href={arc.path.base+'account/address/new'} className='Delivery_link'>+ Add New Address</a></p>
				<AddressPicker addressId={data.shippingAddressId} onChange={this._setAddressId} autoFocus={true}/>
				<Textarea placeholder='example: Deliver after 5pm. Request signature for delivery.' label='Shipping Instructions' value={data.shippingInstructions} onChange={(e)=> setData(requestType, { ...data, shippingInstructions: e.target.value })}/>
				<Continue isMuted={!data.shippingAddressId} disabled={!data.shippingAddressId} onClick={()=> history.push(`${arc.path.base}${requestType}/customer`)}/>
			</div>
		);
	},
});
