import React from 'react';
import AddressPicker from '../AddressPicker';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import Alert from '../Alert';

function status(requestType, data) {
	const { customerAddressId, customerAddressSameAsShipping } = data;

	if (!(customerAddressId || customerAddressSameAsShipping)) {
		return {
			alert: <Alert type='error'><a href={`${arc.path.base}${requestType}/customer`}>Business address</a> required</Alert>,
			isComplete: false,
		};
	}

	return {
		isComplete: true,
	};
}

function isRequired(userType) {
	return userType === 'internal'; // Customers don't need a business address
}

export default React.createClass({
	statics: {
		status,
		isRequired,
	},
	_setAddressId(addressId) {
		setData(this.props.requestType, {...this.props.data, customerAddressId: addressId, customerAddressSameAsShipping: false });
	},
	_setSameAsShipping(e) {
		setData(this.props.requestType, {...this.props.data, customerAddressId: false, customerAddressSameAsShipping: e.target.checked });
	},
	componentDidMount() {
		if (!isRequired(this.props.userType)) {
			history.replace(`${arc.path.base}${this.props.requestType}/review`);
		}
	},
	_setInfo(prop, value) {
		const data = this.props.data ? {...this.props.data} : {};
		
		data.info = data.info ? {...data.info} : {};
		data.info[prop] = value;
		setData(this.props.requestType, data);
	},
	_handleChange(e) {
		this._setInfo(e.currentTarget.name, e.currentTarget.value);
	},
	_handleSubmit(e) {
		e.preventDefault();
	},

	render() {
		const { customerAddressId, customerAddressSameAsShipping } = this.props.data;
		const disabled = !status(this.props.requestType, this.props.data).isComplete;

		return (
			<div className='Customer'>
				<h2>End-User Customer Physical Business Address</h2>
				<p>If the samples are being shipped to the brokerage address or the broker rep’s home address for the end-user customer, please enter the complete End-User Customer Physical Business Address here:</p>
				<p><a href={arc.path.base+'account/address/new'} className='Customer_link'>+ Add New Address</a></p>

				<label className='Customer_check'><input onChange={this._setSameAsShipping} checked={customerAddressSameAsShipping || false} type='checkbox'/> Same as shipping address</label>
				{
					!customerAddressSameAsShipping ? (
						<div>
							<AddressPicker addressId={customerAddressId} onChange={this._setAddressId}/>
						</div>
					) : null
				}
				<Continue isMuted={disabled} disabled={disabled} onClick={()=> history.push(`${arc.path.base}${this.props.requestType}/review`)}/>
			</div>
		);
	},
});
