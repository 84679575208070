import React from 'react';
import Root from './components/Root';
import Error404 from './components/Error404';
import Request from './components/Request';
import Samples from './components/Samples';
import Delivery from './components/Delivery';
import Customer from './components/Customer';
import GetInfo from './components/GetInfo';
import Complete from './components/Complete';
import Account from './components/Account';
import OrderHistory from './components/OrderHistory';
import CustomerHistoryContainer from './components/CustomerHistoryContainer';
import EditAddress from './components/EditAddress';
import Review from './components/Review';
import ResetPassword from './components/ResetPassword';
import Welcome from './components/Welcome';
import Verify from './components/Verify';
import LoginPage from './components/LoginPage';
import Auth from './components/Auth';
import ForgotPassword from './components/ForgotPassword';
import ProductListContainer from './components/ProductListContainer';
import Thanks from './components/Thanks';
import Report from './components/Report';

const base = arc.path.base;

export default {
	[base]: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Request/>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise)']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Samples userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<GetInfo userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise)/products']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Samples userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<ProductListContainer requestType={params.requestType} data={props[params.requestType]} userType={props.user && props.user._ext && props.user._ext.type}/>
				</Samples>
			</Auth>
		</Root>
	),

	[base+':requestType(merchandise)/shipping']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Samples userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Delivery requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise)/customer']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Samples userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Customer userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise)/review']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Samples userType={props.user && props.user._ext && props.user._ext.type} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Review requestType={params.requestType} user={props.user} userType={props.user && props.user._ext && props.user._ext.type} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+'complete']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Complete userType={props.user && props.user._ext && props.user._ext.type} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'account']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Account user={props.user}/>
			</Auth>
		</Root>
	),

	[base+'account/address/new']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<EditAddress/>
			</Auth>
		</Root>
	),
	[base+'account/address/:id']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<EditAddress id={params.id}/>
			</Auth>
		</Root>
	),
	[base+'history']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<OrderHistory  user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'customer-history']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<CustomerHistoryContainer user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'report']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Report user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'account/reset-password']: (params, props)=> (
		<Root user={props.user}>
			<LoginPage>
				<ResetPassword verificationKey={props.location.query.key}/>
			</LoginPage>
		</Root>
	),
	[base+'account/password']: (params, props)=> (
		<Root user={props.user}>
			<LoginPage>
				<ForgotPassword/>
			</LoginPage>
		</Root>
	),
	[base+'account/signup']: (params, props)=> (
		<Root user={props.user}>
			<Welcome/>
		</Root>
	),
	[base+'account/verify']: (params, props)=> (
		<Root user={props.user}>
			<LoginPage>
				<Verify verificationKey={props.location.query.key}/>
			</LoginPage>
		</Root>
	),
	[base+'thanks']: (params, props)=> (
		<Root user={props.user}>
			<Thanks/>
		</Root>
	),
	['*']: (params, props)=> (
		<Root user={props.user}>
			<Auth user={props.user}>
				<Error404/>
			</Auth>
		</Root>
	),
};