import React from 'react';
import request from 'arcdynamic-request';
import exc from '../../exception';
import Alert from '../Alert';
import AddressCompact from '../AddressCompact';

const limitCount = 10;

export default React.createClass({
	_fetch(page = 1) {
		this.setState({isFetching: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			options: {
				filter: {
					search: this.state.search || null,
				},
				limit: {
					count: limitCount,
					offset: (limitCount * page) - limitCount,
				},
				count: 2,
			},
		}).then(res => {
			if (!this.isMounted()) return;

			if (res.success) {
				this.setState({
					isFetching: false,
					addresses: res.data || [],
					error: !res.success ? res.message : null,
					page,
					totalCount: res.count,
				});
			}
		}).catch(exc)
	},
	_purgeAddress(el) {
		this.setState({
			purgeId: el.id,
		})

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.purge',
			params: [el.id],
		}).then((res) => {
			if (this.isMounted() && res.success) {
				const addresses = this.state.addresses.slice(0);
				addresses.splice(addresses.indexOf(el), 1);
				this.setState({
					addresses,
					purgeId: null,
				});
			}
		}).catch(exc);
	},
	_prev() {
		this._fetch(this.state.page-1);
	},
	_next() {
		this._fetch(this.state.page+1);
	},
	_handleSearch(e) {
		this.setState({search: e.target.value}, this._fetch);
	},
	getInitialState() {
		return {
			isFetching: false,
			addresses: [],
			error: null,
			purgeId: null,
			page: 1,
			search: '',
		};
	},
	componentDidMount() {
		this._fetch();
	},
	render() {
		const { onChange, addressId, autoFocus } = this.props;
		const { addresses, purgeId, error, isFetching, totalCount, page, search } = this.state;

		return (
			<div className='AddressPicker'>
				<form className='AddressPicker_search' onSubmit={e => e.preventDefault()}>
					<input type='text' onChange={this._handleSearch} value={search} autoFocus={autoFocus} placeholder='Search addressee, phone, or street address'/>
				</form>
				{
					addresses.length ? (
						<form onSubmit={this._handleSubmit} className='AddressPicker_addrs' data-is-busy={isFetching || null}>
							{
								addresses.map(el => {
									const isSelected = el.id === addressId;
									const isPurging = el.id === purgeId;

									return (
										<div key={el.id} className='AddressPicker_addrs_item' data-is-busy={isPurging || null}>
											<label>
												<input disabled={isPurging} checked={isSelected} onChange={()=> onChange(el.id)} type='radio' name='addr'/>
												<AddressCompact address={el}/>
											</label>
											{
												isSelected ? <a disabled={isPurging} href={arc.path.base+'account/address/'+el.id}>Edit</a> : null
											}
											{
												isSelected ? <a disabled={isPurging} href='#' onClick={(e)=> {
													e.preventDefault();
													this._purgeAddress(el);
												}}>Delete</a> : null
											}
										</div>
									);
								})
							}
						</form>
					) : search && !isFetching ? (
						<div><p>No addresses found for <em><strong>{search}</strong></em></p></div>
					) : null
				}
				{
					totalCount > limitCount ? (
						<div className='AddressPicker_paginate'>
							<button type='button' onClick={this._prev} disabled={page <= 1}>Prev</button>
							<button type='button' onClick={this._next} disabled={totalCount <= (page * limitCount)}>Next</button>
							<span>Showing {(((page - 1) * limitCount) + addresses.length).toLocaleString()} of {totalCount.toLocaleString()}</span>
						</div>
					) : null
				}
				<Alert type='error'>{error}</Alert>
			</div>
		);
	},
});
