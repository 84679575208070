import React from 'react';
import request from 'arcdynamic-request';
import Paginate from '../Paginate';
import Results from '../Results';
import exception from '../../exception';
import history from '../../history';
import querystring from 'querystring';
import Currency from '../Currency';
import Suggest from '../Suggest';
import Modal from '../Modal';
import DatePicker from '../DatePicker';
import svgClose from '../../svgs/close.svg';
import Icon from '../Icon';

const limitCount = 1000;

function formatDate(unixTimestamp) {
	const d = new Date(unixTimestamp*1000);
	return `${d.getUTCMonth()+1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

function ISOtoUSA(string) {
	const x = string.split('-');
	return x[1]+'/'+x[2]+'/'+x[0];
}

function getStartDate() {
	var d = new Date();
	d.setUTCMonth(d.getUTCMonth()-1);
	return d.toISOString().slice(0,10);
}

const Report = React.createClass({
	getInitialState() {
		return {
			items: [],
			totalCount: false,
			page: 1,
			productSku: false,
			usernames: false,
			editStartDate: false,
			editEndDate: false,
		};
	},
	_fetchUsernames() {
		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['AVI_ALL_USERS'],
		}, {
			expires: 1000*60*1,
		}).then(res => {
			if (!this.isMounted()) return;

			let usernames = false;

			if (Array.isArray(res.data)) {
				usernames = res.data.sort((a,b)=> {
					const A = (a.FIRST_NAME ? a.FIRST_NAME.toUpperCase()+' ' : '')+(a.LAST_NAME ? a.LAST_NAME.toUpperCase()+' ' : '')+(a.USERNAME ? `(${a.USERNAME})` : '');
					const B = (b.FIRST_NAME ? b.FIRST_NAME.toUpperCase()+' ' : '')+(b.LAST_NAME ? b.LAST_NAME.toUpperCase()+' ' : '')+(b.USERNAME ? `(${b.USERNAME})` : '');

					if (A < B) {
						return -1;
					}
					if (A > B) {
						return 1;
					}

					return 0;
				})
			}
			this.setState({
				usernames,
			});
		}).catch(exception);
	},
	_fetch() {
		const { 
			status,
			search,
			username,
			end,
		} = this.props.location.query;

		const start = this.props.location.query.start || getStartDate();

		if (!search) {
			this.setState({
				isFetching: false,
				items: [],
				totalCount: false,
				page: 1,
				productSku: false,
			});
			return;
		}

		this.setState({
			isFetching: true
		});

		const page = Number(this.props.location.query.page) || 1;

		const filter = [];

		if (status) {
			filter.push({
				code: 'ORDER_STATUS_CODE',
				text: status,
				type: 'match',
			});
		}
		if (username) {
			filter.push({
				code: 'USERNAME',
				text: username,
				type: 'match',
			});
		}
		if (search) {
			filter.push({
				code: 'PRODUCTS',
				text: `%"productSku":"${search}"%`,
				type: 'match',
			});
		}

		if (start && end) {
			const startDate = new Date(start);
			const endDate = new Date(end);
			if (startDate.toString() !== 'Invalid Date' && endDate.toString() !== 'Invalid Date') {
				endDate.setUTCDate(endDate.getUTCDate()+1);
				filter.push({
					code: 'ORDER_DATE',
					text: `${Math.round(startDate.getTime()/1000)},${Math.round(endDate.getTime()/1000)}`,
					type: 'range',
				})
			}
		} else {
			if (start) {
				const date = new Date(start);
				if (date.toString() !== 'Invalid Date') {
					filter.push({
						code: 'ORDER_DATE',
						text: Math.round(date.getTime()/1000).toString(),
						type: '>=',
					});
				}
			}
			if (end) {
				const date = new Date(end);
				date.setUTCDate(date.getUTCDate()+1);
				if (date.toString() !== 'Invalid Date') {
					filter.push({
						code: 'ORDER_DATE',
						text: Math.round(date.getTime()/1000).toString(),
						type: '<=',
					});
				}
			}
		}

		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: ['code', 'AVI_CUSTOMER_ORDERS', {
				column: [
					'PRODUCTS',
					'FIRST_NAME',
					'LAST_NAME',
					'PURCHASE_ORDER_CODE',
					'USERNAME',
					'ORDER_DATE',
					'ORDER_STATUS_CODE',
				],
				filter,
				filterType: 'and',
				limit: {
					count: limitCount,
					offset: (limitCount * page) - limitCount,
				},
				count: 2,
			}],
		}, {
			expires: 1000*60*5, // 5 minutes
		}).then(res => {
			const items = [];

			(res.data || []).forEach(el => {
				try {
					el.product = JSON.parse(el.PRODUCTS).filter(el => el.productSku === search)[0];
					if (el.product) {
						items.push(el);
					}
				} catch (e) {
					// do nothing
				}
			});

			this.setState({
				isFetching: false,
				items: items,
				totalCount: res.success ? Number(res.count) : null,
				page: page,
				productSku: search,
			})
		}).catch(exception);
	},
	_setQuery(key, value) {
		const query = {...this.props.location.query};
		delete query.page;

		if (value) {
			query[key] = value;
		} else {
			delete query[key];
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_setStartDate(dateString) {
		this._setQuery('start', dateString);
		this.setState({editStartDate: false});
	},
	_setEndDate(dateString) {
		this._setQuery('end', dateString);
		this.setState({editEndDate: false});
	},
	componentDidMount() {
		this._fetch();
		this._fetchUsernames();
	},
	componentDidUpdate(prevProps) {
		if (this.props.location.href !== prevProps.location.href) {
			this._fetch();
		}
	},
	_clearSearch() {
		this._setQuery('search', false);
	},
	_renderTable(items) {
		let total = 0;
		let quantity = 0;

		items.forEach(el => {
			total += (el.product.quantity * el.product.cost);
			quantity += el.product.quantity;
		});

		return (
			<div>
				<div className='Report_total'>
					<span>{items[0].product.name} ({items[0].product.productSku})</span>
					<span>QTY: {quantity} | {this.state.totalCount > limitCount ? `Total for these ${items.length} results:` : 'Total:'} <Currency>{total}</Currency></span>
				</div>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Order Date</th>
							<th>Status</th>
							<th>QTY</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{
							items.map(el => {
								const heading = [];
								if (el.FIRST_NAME) {
									heading.push(el.FIRST_NAME);
								}
								if (el.LAST_NAME) {
									heading.push(el.LAST_NAME);
								}

								let cost = 'N/A';
								if (el.product.cost && el.product.quantity) {
									const num = Number(el.product.cost);
									if (num === num) {
										cost = <Currency>{num * el.product.quantity}</Currency>;
									}
								}

								return (
									<tr key={el.PURCHASE_ORDER_CODE}>
										<td>{heading.join(' ')} ({el.USERNAME})</td>
										<td>{formatDate(el.ORDER_DATE)}</td>
										<td>{el.ORDER_STATUS_CODE}</td>
										<td>{el.product.quantity}</td>
										<td>{cost}</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</div>
		);
	},
	render() {
		const { 
			items,
			page,
			totalCount,
			isFetching,
			usernames,
			editStartDate,
			editEndDate,
			productSku
		} = this.state;

		const { location } = this.props;
		const { 
			end,
			username,
			status,
		} = location.query;

		if (items == null) return <div/>;

		const minDate = new Date('2017-03-01');
		const maxDate = new Date();
		const start = location.query.start || getStartDate();

		return (
			<div className='Report'>
				<h1>Order Report</h1>
				<Suggest handleSku={sku => this._setQuery('search', sku)}/>
				<div className='Report_nav'>
					<div className='Report_nav_filter'>
						<div className='Report_date'>
							<button type='button' onClick={()=> this.setState({editStartDate: true})} className='Report_date_label'>{start ? ISOtoUSA(start) : 'Start date'}</button>
							{
								start ? <button type='button' onClick={()=> this._setQuery('start', null)} className='Report_date_clear'><Icon svg={svgClose}/></button> : null
							}
						</div>
						{
							editStartDate ? (
								<Modal onClose={()=> this.setState({editStartDate: false})}>
									<div style={{width: '100vw', maxWidth: '400px'}}>
										<DatePicker setDate={this._setStartDate} initialDate={start ? new Date(start) : maxDate} minDate={minDate} maxDate={maxDate} selectedDate={start}/>
									</div>
								</Modal>
							) : null
						}
					</div>
					<div className='Report_nav_filter'>
						<div className='Report_date'>
							<button type='button' onClick={()=> this.setState({editEndDate: true})} className='Report_date_label'>{end || 'End date'}</button>
							{
								end ? <button type='button' onClick={()=> this._setQuery('end', null)} className='Report_date_clear'><Icon svg={svgClose}/></button> : null
							}
						</div>
						{
							editEndDate ? (
								<Modal onClose={()=> this.setState({editEndDate: false})}>
									<div style={{width: '100vw', maxWidth: '400px'}}>
										<DatePicker setDate={this._setEndDate} initialDate={end ? new Date(end) : maxDate} minDate={minDate} maxDate={maxDate} selectedDate={end}/>
									</div>
								</Modal>
							) : null
						}
					</div>
					<div className='Report_nav_filter'>
						Status: <select value={status || ''} onChange={(e)=> this._setQuery('status', e.target.value)}>
							<option value=''>All</option>
							<option value='invalid'>Invalid</option>
							<option value='pending'>Pending</option>
							<option value='processing'>Processing</option>
							<option value='completed'>Completed</option>
							<option value='cancelled'>Cancelled</option>
							<option value='returned'>Returned</option>
							<option value='refunded'>Refunded</option>
						</select>
					</div>
					<div className='Report_nav_filter'>
						User: <select value={username || ''} onChange={(e)=> this._setQuery('username', e.target.value)}>
							<option value=''>All</option>
							{
								usernames ? usernames.map(el => (
									<option value={el.USERNAME} key={el.USERNAME}>
									{el.FIRST_NAME ? el.FIRST_NAME+' ' : null}
									{el.LAST_NAME ? el.LAST_NAME+' ' : null}
									{el.USERNAME ? `(${el.USERNAME})` : null}
									</option>
								)) : null
							}
						</select>
					</div>
				</div>
				<div className='Report_nav'>
					<div className='Report_nav_results'>
						{
							productSku ? <Results count={limitCount} page={page} itemCount={items.length} totalCount={totalCount} clearSearch={this._clearSearch} query={'SKU:'+productSku}/> : null
						}
					</div>
				</div>
				<div className='Report_body' data-is-busy={isFetching || null}>
					{
						items && items.length ? this._renderTable(items) : null
					}
					<div className='Report_paginate'>
						<Paginate query={location.query} pathname={location.pathname} count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
					</div>
				</div>
			</div>
		);
	},
});

export default ({ user, location })=> {
	return user && user.typeCode === 'super' ? <Report location={location}/> : <div>Access to this page restricted to admins.</div>;
};