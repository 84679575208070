import React from 'react';
import BreadCrumbs from '../BreadCrumbs';

export default ({ requestType, userType, children })=> {
	const links = [
		{
			href: arc.path.base,
			text: 'Home',
			disabled: false,
		},
		{
			href: arc.path.base+requestType,
			text: 'Info',
			disabled: false,
		},
		{
			href: arc.path.base+requestType+'/products',
			text: 'Products',
			disabled: false,
		},
		{
			href: arc.path.base+requestType+'/shipping',
			text: 'Shipping',
			disabled: false,
		},
	];

	if (userType === 'internal') {
		links.push({
			href: arc.path.base+requestType+'/customer',
			text: 'Customer',
			disabled: false,
		});
	}

	links.push({
		href: arc.path.base+requestType+'/review',
		text: 'Review',
		disabled: false,
	});

	return (
		<div className='Samples'>
			<BreadCrumbs links={links}/>
			{ children }
		</div>
	);
};
