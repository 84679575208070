import React from 'react';
import SignUp from '../SignUp';
import svgLogo from '../../svgs/logo.svg';

export default React.createClass({
	render() {
		return (
			<div className='Welcome'>
				<div className='Welcome_header'>
					<div className='Welcome_header_dc-logo' dangerouslySetInnerHTML={{__html: svgLogo}}/>
					<img className='Welcome_header_avi-logo' src={arc.path.media+'v0/avi-logo.png'} alt=''/>
				</div>
				<div className='Welcome_body'>
					<div className='Welcome_body_form'>
						<SignUp/>
					</div>
					<div className='Welcome_body_message'>
						<h3>Welcome to our new Food Service Request Portal for AVI</h3>
						<p>Please fill out the signup form and a representative will be notified to activate your account.</p>
						<p>Once activated you can log-in and submit your requests.</p>
						<p>If you have any problems or need assistance, please contact your representative.</p>
						<p>Best,<br/> David's Cookies<br/> <em>A Bite Above The Rest!</em></p>
					</div>
				</div>
			</div>
		);
	},
});
