import React from 'react';
import CustomerHistory from '../CustomerHistory';

export default ({ user, location })=> {
	const userType = user._ext.type;

	if (userType !== 'internal') return <div>No customers associated with this account.</div>;

	return <CustomerHistory userType={userType} username={user.username} location={location}/>;
}
