import React from 'react';
import Header from '../Header';
import Main from '../Main';
import catchFocus from '../../catch-focus';

export default React.createClass({
	componentDidMount() {
		catchFocus(this.refs.root);
	},
	render() {
		const { user, children } = this.props;

		return (
			<div className='Root' ref='root'>
				<div className='Root_body'>
					<Header user={user}/>
					<Main>{children}</Main>
				</div>
			</div>
		);
	},
});
