import React from 'react';
import MerchInfo from '../MerchInfo';

export default React.createClass({
	render() {
		let ret = null;

		switch (this.props.requestType) {
			case 'merchandise':
				ret = <MerchInfo heading='Merchandise Request Information' {...this.props}/>;
				break;
		}

		return ret;
	},
});
