import React from 'react';
import Select from '../Select';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import meetsInfoRequirements from '../../meets-info-requirements';

export default React.createClass({
	_handleSubmit(e) {
		const { data, requestType } = this.props;

		e.preventDefault();

		if (data && meetsInfoRequirements(requestType, data.info, this.props.userType)) {
			history.push(arc.path.base+requestType+'/products');
		}
	},
	_handleChange(e) {
		const key = e.currentTarget.name;
		const value = e.currentTarget.value;
		const data = {...this.props.data};
		
		data.info = {...data.info};
		data.info[key] = value;
		setData(this.props.requestType, data);
	},
	render() {
		const info = this.props.data && this.props.data.info || {};
		const { userType } = this.props;

		return (
			<div className='MerchInfo'>
				<h2>{this.props.heading}</h2>
				<form onSubmit={this._handleSubmit}>
					<Select 
						required
						value={info.purposeValue}
						name='purposeValue'
						label='What is the purpose of this request?'
						onChange={this._handleChange}
					>
						<option disabled/>
						<option>Initial Request</option>
						<option>Reorder</option>
					</Select>
					<p>All merchandise will ship only via UPS Ground. Please allow up to 5 business days for approval of this request and up to 15 business days after approval for delivery.</p>
					<Continue isMuted={!meetsInfoRequirements(this.props.requestType, info, userType)}/>
				</form>
			</div>
		);
	},
});
