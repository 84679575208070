import React from 'react';

export default ()=> (
	<div className='Request'>
		<h2>Click Below to Begin Your Request</h2>
		<div className='Request_list'>
			<a className='Request_item' href={arc.path.base+'merchandise'}>
				<div className='Request_item_icon'>
					<img alt='' src={arc.path.media+'v0/merch.jpg'}/>
				</div>
				<div>Merchandising</div>
			</a>
		</div>
	</div>
)