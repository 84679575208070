import React from 'react';
import MiniOrder from '../MiniOrder';

export default React.createClass({
	getInitialState() {
		return {
			isOpen: false,
		};
	},

	_toggle() {
		this.setState({isOpen: !this.state.isOpen});
	},

	render() {
		const { order } = this.props;

		const o = {
			created: order.ORDER_DATE,
			shipmentDate: order.REQUESTED_DELIVERY_DATE,
			statusCode: order.ORDER_STATUS_CODE,
			purchaseOrderCode: order.PURCHASE_ORDER_CODE,
			products: order.PRODUCTS ? JSON.parse(order.PRODUCTS) : [],
			shipments: order.SHIPMENTS ? JSON.parse(order.SHIPMENTS) : [],
			message: order.SPECIAL_INSTRUCTIONS,
			address: {
				attentionName: order.SHIPPING_ADDRESS_ATTENTION_NAME,
				addressee: order.SHIPPING_ADDRESS_ADDRESSEE,
				addressOne: order.SHIPPING_ADDRESS_ADDRESS_ONE,
				addressTwo: order.SHIPPING_ADDRESS_ADDRESS_TWO,
				addressFour: order.SHIPPING_ADDRESS_ADDRESS_FOUR,
				city: order.SHIPPING_ADDRESS_CITY,
				state: order.SHIPPING_ADDRESS_STATE,
				postalCode: order.SHIPPING_ADDRESS_POSTAL_CODE,
				phone: order.SHIPPING_ADDRESS_PHONE,
			},
		};

		const billingAddress = {
			attentionName: order.BUSINESS_ADDRESS_ATTENTION_NAME,
			addressee: order.BUSINESS_ADDRESS_ADDRESSEE,
			addressOne: order.BUSINESS_ADDRESS_ADDRESS_ONE,
			addressTwo: order.BUSINESS_ADDRESS_ADDRESS_TWO,
			addressFour: order.BUSINESS_ADDRESS_ADDRESS_FOUR,
			city: order.BUSINESS_ADDRESS_CITY,
			state: order.BUSINESS_ADDRESS_STATE,
			postalCode: order.BUSINESS_ADDRESS_POSTAL_CODE,
			phone: order.BUSINESS_ADDRESS_PHONE,
		};

		return <MiniOrder productLabel='Merchandise' order={o} purpose={order.REQUEST_PURPOSE} billingAddress={billingAddress}/>
	},
});