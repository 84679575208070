import React from 'react';
import request from 'arcdynamic-request';
import FormElement from '../FormElement';
import exc from '../../exception';

const newAddress = {
	typeCode: '',
	addressee: '',
	attentionName: '',
	addressOne: '',
	addressTwo: '',
	addressFour: '',
	country: '',
	city: '',
	state: '',
	postalCode: '',
	phone: '',
};

export default React.createClass({
	propTypes: {
		address: React.PropTypes.object,
	},
	getDefaultProps() {
		return {
			attentionName: true,
			phone: true,
			autoFocus: true,
		};
	},
	getInitialState() {
		const address = { ...newAddress };

		if (this.props.address) {
			Object.keys(address).forEach(key => {
				if (this.props.address[key]) {
					address[key] = this.props.address[key];
				}
			})
		}

		return {
			countries: [],
			address,
		};
	},
	componentDidMount() {
		Promise.all(arc.countries.map(el => {
			return request(arc.path.store, {
				service: 'core',
				action: 'location.State.get',
				params: [{
					filter: {
						countryCode: el.code,
						excludeTerritories: el.excludeTerritories,
					},
					order: 'a-z',
				}],
			}).then(res => ({
				name: el.name,
				code: el.code,
				states: res.data || [],
			}));
		})).then(values => {
			if (!this.isMounted()) return;

			this.setState({
				countries: values,
			});
		}).catch(exc);
	},
	handleChange(e) {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		const address = { ...this.state.address, [name]: value };

		this.setState({address});

		if (this.props.onChange) {
			this.props.onChange(address);
		}
	},
	handleCountry(e) {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		const address = { ...this.state.address, [name]: value, state: '' };

		this.setState({address});

		if (this.props.onChange) {
			this.props.onChange(address);
		}
	},
	getAddress() {
		return { ...this.state.address };
	},
	render() {
		const addr = this.state.address;
		const country = addr.country && this.state.countries.filter(x => x.code === addr.country)[0];

		return (
			<div>
				<FormElement label='Account / Business Name'>
					<input
						name='addressee'
						required
						type='text'
						maxLength='35'
						value={addr.addressee}
						autoComplete='name'
						onChange={this.handleChange}
						autoFocus={this.props.autoFocus}
					/>
				</FormElement>
				{ 
					this.props.attentionName ? (
						<FormElement label='Contact Name'>
							<input
								name='attentionName'
								type='text'
								maxLength='35'
								value={addr.attentionName}
								onChange={this.handleChange}
							/>
						</FormElement>
					) : null
				}
				<FormElement label='Address'>
					<input
						name='addressOne'
						required
						type='text'
						maxLength='35'
						value={addr.addressOne}
						autoComplete='address-line1'
						onChange={this.handleChange}
					/>
				</FormElement>
				<FormElement label='Address 2'>
					<input
						name='addressTwo'
						type='text'
						maxLength='35'
						value={addr.addressTwo}
						autoComplete='address-line2'
						onChange={this.handleChange}
					/>
				</FormElement>
				<FormElement label='City'>
					<input
						name='city'
						required
						type='text'
						maxLength='35'
						value={addr.city}
						autoComplete='address-level2'
						onChange={this.handleChange}
					/>
				</FormElement>
				<FormElement label='Country'>
					<select 
						name='country'
						autoComplete='country'
						required
						value={addr.country}
						onChange={this.handleCountry}
					>
						<option disabled value=''/>
						{
							this.state.countries.map(el => <option key={el.code} value={el.code}>{el.name}</option>)
						}
					</select>
				</FormElement>
				<FormElement label='State' disabled={country && !country.states.length}>
					<select 
						name='state'
						autoComplete='address-level1'
						required
						value={addr.state}
						onChange={this.handleChange}
						disabled={country && !country.states.length}
					>
						<option disabled value=''/>
						{
							country && country.states.length ? country.states.map(x => <option key={x.id} value={x.code}>{x.name}</option>) : null
						}
					</select>
				</FormElement>
				<FormElement label='Zip Code'>
					<input
						name='postalCode'
						required
						type='tel'
						maxLength='35'
						value={addr.postalCode}
						autoComplete='postal-code'
						onChange={this.handleChange}
					/>
				</FormElement>
				<FormElement label='Is this address your:'>
					<select 
						name='typeCode'
						required
						value={addr.typeCode}
						onChange={this.handleChange}
					>
						<option disabled value=''/>
						<option value='residential'>Home / Residential</option>
						<option value='business'>Business</option>
					</select>
				</FormElement>
				{
					this.props.phone ? (
						<FormElement label='Phone'>
							<input
								name='phone'
								type='tel'
								maxLength='35'
								value={addr.phone}
								autoComplete='tel-national'
								onChange={this.handleChange}
							/>
						</FormElement>
					) : null
				}
				<FormElement label='Email Address'>
					<input
						name='addressFour'
						required
						type='email'
						value={addr.addressFour}
						autoComplete='email'
						onChange={this.handleChange}
					/>
				</FormElement>
			</div>
		);
	},
});