// This module throws an error for exceptions that
// happen within a Promise, so that they may be logged.

// Catching unhandled rejections in Bluebird (only part of the dev build)
{
	if (typeof Promise.config !== 'undefined') {
		Promise.config({
			longStackTraces: false,
			warnings: {
				wForgottenReturn: false
			},
			cancellation: true,
		});

		Promise.onPossiblyUnhandledRejection(err => setTimeout(() => { throw err }, 0))
	}
}

// If an unhandled rejection happens, 
// that means a promise was missing a catch and needs to be fixed
window.addEventListener('unhandledrejection', err => setTimeout(() => { throw err }, 0))

export default (err) => setTimeout(()=> { throw err }, 0)