import React from 'react';
import request from 'arcdynamic-request';
import { setUser } from '../../actions';
import exc from '../../exception';
import history from '../../history';
import Control from 'react-control';

export default React.createClass({
	_logout() {
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.logout',
			}).then(res => {
				if (res.success) setUser(false);
				return res;
			}),
		]).then(() => {
			history.push(arc.path.base);
		}).catch(exc);
	},
	render() {
		const { user } = this.props;

		return (
			<div className='Header'>
				<div className='Header_wrap'>
					<a href={arc.path.base} className='Header_home'>
						David's Foodservice Request Portal for AVI
					</a>
					<a href='tel:+1-800-217-2938' className='Header_link'>
						(800) 217-2938
					</a>
					{
						user ? <a className='Header_link' href={arc.path.base+'account'}>Account</a> : null
					}
					{
						user ? <Control onClick={this._logout} className='Header_link'>Log Out</Control> : null
					}
				</div>
			</div>
		);
	},
});
